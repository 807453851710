import { Subject, of } from 'rxjs';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize, map, mergeMap } from 'rxjs/operators';
import { MainService } from '../../services';
import { HOLISTA_CONSTANT } from '../../constants';
import { LocationUtils, StorageUtility } from 'src/app/utils';
import { SearchModalComponent } from 'src/app/modals';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
const appleQr =  '../../../assets/img/jpg/ios-qr.jpeg'
const androidQr =  '../../../assets/img/png/android-qr.png'
const googlePlayIcon =  '../../../assets/img/png/google-play-badge.png'
const applePlayIcon = '../../../assets/img/png/apple-store-badge.png'

@Component({
  selector: 'app-main-body',
  templateUrl: './main-body.component.html',
  styleUrls: ['./main-body.component.scss']
})
export class MainBodyComponent implements OnInit {
  mainSearchForm: FormGroup;
  searchBundleText = new Subject();
  searchProviderText = new Subject();
  searchLocationText = new Subject();
  modalRef: MdbModalRef<SearchModalComponent> | null = null;
  notFound = false;
  mainSearchFormData: any;
  networkCode: string;
  options = [
    { value: '1', label: 'Provider' },
    { value: '2', label: 'Facility' },
  ];
  bundleList: any[] = [];
  providerList: any[] = [];
  locationList: any[] = [];
  showAppAnnouncement = true;
  isLoading = {
    bundles: false,
    locations: false,
    providers: false,
  };
  providerSearchParams = {};
  DEFAULT_RADIUS = HOLISTA_CONSTANT.DEFAULT_RADIUS;
  PAGINATION_QUERY = {
    ...HOLISTA_CONSTANT.defaultPaginationQuery,
  };
  backupBundle: any;
  appleQr = appleQr
  androidQr = androidQr;
  googlePlayIcon = googlePlayIcon;
  applePlayIcon = applePlayIcon;
  backupProvider: any;
  isSmallScreen: boolean = false;
  displayClearButton = {
    provider: false,
    service: false,
  }
  isSearchByProvider: boolean = false;

  @Output() isMainPage = new EventEmitter<any>();

  constructor(
    private _formBuilder: FormBuilder,
    private _mainService: MainService,
    private _locationUtils: LocationUtils,
    private _storageUtility: StorageUtility,
    private modalService: MdbModalService,
  ) {
    this.setMainSearchForm();
    this.mainSearchFormData = this.mainSearchForm.value;
    this.searchBundleText
      .pipe(map(event => event)
        , debounceTime(300)
        , distinctUntilChanged((previousValue: any, currentValue: any) => {
          if (previousValue != currentValue)
            return false;
          return true;
        })
        , mergeMap(keyword => of(keyword)))
      .subscribe((keyword) => {
        if (typeof this.mainSearchForm.value.bundle === 'string') {
          this.displayClearButton.service = keyword?.length > 2;
          this.backupBundle = this.mainSearchForm.value.bundle;
          this.mainSearchForm.controls['optionClicked'].setValue(typeof this.mainSearchForm.value.bundle === 'string' ? false : true);
        }
        if (keyword?.length > 2 || keyword?.displayName || keyword?.bundleName) {
          this.fetchBundle(typeof keyword === 'string' ? keyword : (keyword?.displayName || keyword?.bundleName));
        } else if (!keyword?.length) {
          this.bundleList.length = 0;
          delete this.providerSearchParams['serviceId'];
        };
      });
    this.searchProviderText
      .pipe(map(event => event)
        , debounceTime(300)
        , distinctUntilChanged((previousValue: any, currentValue: any) => {
          if (previousValue != currentValue)
            return false;
          return true;
        })
        , mergeMap(keyword => of(keyword)))
      .subscribe((keyword) => {
        if (typeof this.mainSearchForm.value.provider === 'string') {
          this.displayClearButton.provider = keyword?.length > 2;
          this.backupProvider = this.mainSearchForm.value.provider;
          this.mainSearchForm.controls['optionClicked'].setValue(typeof this.mainSearchForm.value.provider === 'string' ? false : true);
        }
        if (keyword?.length > 2 || keyword?.displayName || keyword?.provider) {
          this.fetchProvider(typeof keyword === 'string' ? keyword : (keyword?.displayName || keyword?.provider));
        } else if (!keyword?.length) {
          this.bundleList.length = 0;
          delete this.providerSearchParams['providerId'];
        };
      });
    this.searchLocationText
      .pipe(map(event => event)
        , debounceTime(300)
        , distinctUntilChanged((previousValue: any, currentValue: any) => {
          if (previousValue != currentValue)
            return false;
          return true;
        })
        , mergeMap(keyword => of(keyword)))
      .subscribe((keyword) => {
        if (keyword?.length > 2 || keyword?.value) {
          this.fetchLocations(keyword);
        } else if (!keyword?.length) {
          delete this.providerSearchParams['longitude'];
          delete this.providerSearchParams['latitude'];
          delete this.providerSearchParams['radius'];
          this.fetchLocations();
        };
      });
  }

  ngOnInit(): void {
    this.isSmallScreen = window.innerWidth <= 575;
    this.networkCode = this._storageUtility.get('local', 'networkCode') || 'HPS';
    this.fetchLocations();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isSmallScreen = window.innerWidth <= 575;
    if (window.innerWidth > 575 && this.modalRef) {
      this.modalRef.close();
    }
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.mainSearchForm.valid) {
      this.networkCode = this._storageUtility.get('local', 'networkCode') || 'HPS';
      this.mainSearchForm.controls['searchClicked'].setValue(true);
      this.mainSearchForm.controls['networkCode'].setValue(this.networkCode);
      this.mainSearchFormData = this.mainSearchForm.value;
      this.searchProviders();
    }
  }

  clearAutocompleteSearch() {
    if (!this.isSearchByProvider) {
      this.searchBundleText.next('');
      this.mainSearchForm.controls['bundle'].setValue(null);
      this.displayClearButton.service = false;
    } else {
      this.searchProviderText.next('');
      this.mainSearchForm.controls['provider'].setValue(null);
      this.displayClearButton.provider = false;
    }
  }

  fetchLocations(keyword?: string) {
    this.isLoading.locations = true;
    this.locationList.length = 0;
    this.notFound && (this.notFound = false);
    this._mainService.getLocations({ ...keyword && { keyword } })
      .pipe(finalize(() => { this.isLoading.locations = false; }))
      .subscribe({
        next: response => {
          const { success, data } = response;
          if (success) {
            if (data?.length) {
              this.locationList = response.data.map((location: any) => ({ ...location, label: `${location.city}, ${location.st}`, value: location.city }));
            } else this.notFound = true;
          };
        },
        error: error => {
          console.log('Error getting locations', error);
        }
      })
  }

  setMainSearchForm() {
    this.mainSearchForm = this._formBuilder.group({
      searchBy: ['service'],
      provider: [null],
      bundle: [null, Validators.required],
      location: [null],
      searchClicked: [false],
      optionClicked: [false],
      providers: [null],
      physicians: [null],
      radius: [null],
      physicianId: [null],
      pagination: [null],
      networkCode: [null],
    });
  }

  fetchBundle(keyword: string) {
    this.isLoading.bundles = true;
    this.bundleList.length = 0;
    this.notFound && (this.notFound = false);
    this._mainService.getBundles({ keyword, networks: this.networkCode })
      .pipe(finalize(() => { this.isLoading.bundles = false; }))
      .subscribe({
        next: response => {
          const { data, success } = response;
          if (success) {
            if (data?.rows?.length) {
              this.bundleList = data.rows.map((bundle: any) => ({
                // ...bundle,
                displayName: bundle?.displayName,
                bundleName: bundle?.bundleName,
                serviceBundleId: bundle?.serviceBundleId,
                label: (bundle?.displayName || bundle?.bundleName),
                value: bundle?.bundleCode,
              }));
            } else this.notFound = true;
          };
        },
        error: error => {
          console.log('Error getting bundles', error);
        }
      });
  }

  fetchProvider(keyword: string) {
    // this.isLoading.providers = true;
    this.providerList.length = 0;
    this.notFound && (this.notFound = false);
    this._mainService.getProviders({ keyword, networks: this.networkCode })
      .pipe(finalize(() => {
        // this.isLoading.bundles = false;
      }))
      .subscribe({
        next: response => {
          const { data, success } = response;
          if (success) {
            let secondEntityTypeData: any = []
            if (data?.length) {
              secondEntityTypeData = data.filter(eachData => eachData.provider.entityType == 2)
            }
           
            if (secondEntityTypeData?.length) {
              this.providerList = secondEntityTypeData.map((data: any) => ({
                displayName: data?.provider.displayName,
                businessName: data?.provider.businessName,
                id: data?.provider.id,
                label: (data?.provider.displayName || data?.provider.businessName),
                value: data?.provider.id,
                entityType: data?.provider.entityType,
              }));
            } else this.notFound = true;
          };
        },
        error: error => {
          console.log('Error getting bundles', error);
        }
      });
  }

  onBundleDisplay = (bundle?): string | any => {
    if (typeof (bundle) === 'string') {
      return bundle;
    }
    return bundle ? (bundle.label || bundle.displayName || bundle.bundleName || undefined) : undefined;
  }

  onProviderDisplay = (provider?): string | any => {
    if (typeof (provider) === 'string') {
      return provider;
    }
    return provider ? (provider.label || provider.displayName || provider.businessName || undefined) : undefined;
  }

  onLocationDisplay = (location?): string | undefined => {
    if (typeof (location) === 'string') {
      return location;
    }
    return location ? location.label : undefined
  }

  updateMainSearchFilter(event) {
    const { searchBy, searchClicked, location, bundle, radius, physicianId, pagination } = event.searchForm;
    this.mainSearchForm.controls['searchBy'].setValue(searchBy);
    this.mainSearchForm.controls['searchClicked'].setValue(searchClicked);
    this.mainSearchForm.controls['optionClicked'].setValue(typeof bundle === 'string' ? false : true);
    this.mainSearchForm.controls['bundle'].setValue(bundle);
    bundle && this.onBundleDisplay(bundle);
    this.mainSearchForm.controls['radius'].setValue(radius);
    this.mainSearchForm.controls['physicianId'].setValue(physicianId);
    this.mainSearchForm.controls['location'].setValue(location);
    this.mainSearchForm.controls['pagination'].setValue(pagination);
    if (radius) {
      this.providerSearchParams['radius'] = radius;
    } else {
      delete this.providerSearchParams['radius'];
    }
    if (physicianId) {
      this.providerSearchParams['physicianId'] = physicianId;
    } else {
      delete this.providerSearchParams['physicianId'];
    }
    this.searchProviders();
  }

  submitMainFilter() {
    this.showAppAnnouncement = false;
    if (this.mainSearchForm.value.location) {
      const { lat, lng } = this.mainSearchForm.value.location;
      if (lat && lng) {
        this.providerSearchParams['radius'] = this.DEFAULT_RADIUS;
      }
    } else {
      this.mainSearchForm.controls['radius'].setValue(null);
    }
    this.mainSearchForm.controls['searchClicked'].setValue(true);
    this.mainSearchForm.controls['networkCode'].setValue(this.networkCode);
    this.mainSearchFormData = this.mainSearchForm.value;
    this.isMainPage.emit(!this.mainSearchFormData.searchClicked && !this.mainSearchFormData.optionClicked);
    this.searchProviders();
  }

  searchProviders() {
    this.isLoading.providers = true;
    this.mainSearchForm.controls['providers'].setValue([]);
    this.providerSearchParams['entityType'] = '2';
    this.mainSearchFormData = this.mainSearchForm.value;
    if (this.mainSearchForm.value?.pagination) {
      this.PAGINATION_QUERY = this.mainSearchForm.value.pagination;
    }
    const { totalCount, page, ...rest } = this.mainSearchForm.value.pagination || this.PAGINATION_QUERY;
    if (this.isSearchByProvider) {
      delete this.providerSearchParams['serviceId'];
    } else {
      delete this.providerSearchParams['facilityId'];
      delete this.providerSearchParams['physicianId'];
    }
    this._mainService.searchProviders({ ...this.providerSearchParams, ...rest, networks: this.networkCode, })
      .pipe(finalize(() => { this.isLoading.providers = false; }))
      .subscribe({
        next: response => {
          if (response.success) {
            this.mainSearchForm.controls['providers'].setValue(response?.data?.rows || []);
            this.PAGINATION_QUERY = {
              ...this.PAGINATION_QUERY,
              totalCount: response.data.count,
            }
            this.mainSearchForm.controls['pagination'].setValue(this.PAGINATION_QUERY);
            this.mainSearchFormData = this.mainSearchForm.value;
            this.isMainPage.emit(!this.mainSearchFormData.searchClicked && !this.mainSearchFormData.optionClicked);
          }
        },
        error: error => {
          console.log('Error getting searched providers.', error);
        }
      });
  }

  onBundleOptionSelected(option: any) {
    if (this.isSearchByProvider) {
      this.displayClearButton.provider = true;
      const { id, entityType, isChipSelected } = option;
      this.providerSearchParams[entityType === 1 ? 'physicianId' : 'facilityId'] = id;
      this.mainSearchForm.controls['optionClicked'].setValue(true);
      this.mainSearchForm.controls['pagination'].setValue(HOLISTA_CONSTANT.defaultPaginationQuery);
      if (isChipSelected) {
        this.mainSearchForm.controls['provider'].setValue(option);
        this.mainSearchForm.controls['optionClicked'].setValue(true);
        this.onProviderDisplay(option);
        this.submitMainFilter();
      }
    } else {
      this.displayClearButton.service = true;
      const { serviceBundleId, isChipSelected } = option;
      this.providerSearchParams['serviceId'] = serviceBundleId;
      this.mainSearchForm.controls['optionClicked'].setValue(true);
      this.mainSearchForm.controls['pagination'].setValue(HOLISTA_CONSTANT.defaultPaginationQuery);
      if (isChipSelected) {
        this.mainSearchForm.controls['bundle'].setValue(option);
        this.mainSearchForm.controls['optionClicked'].setValue(true);
        this.onBundleDisplay(option);
        this.submitMainFilter();
      }
    }
  }

  onLocationOptionSelected(option: any) {
    const { lng, lat } = option;
    this.providerSearchParams['latitude'] = lat;
    this.providerSearchParams['longitude'] = lng;
    this.mainSearchForm.controls['radius'].setValue(HOLISTA_CONSTANT.DEFAULT_RADIUS);
    this.providerSearchParams['radius'] = HOLISTA_CONSTANT.DEFAULT_RADIUS;
  }

  handleSelectCurrentLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const loc: any = await this._locationUtils.getLocationDetails(position);
        if (loc) {
          this.providerSearchParams['latitude'] = loc.lat;
          this.providerSearchParams['longitude'] = loc.lng;
          this.mainSearchForm.controls['location'].setValue({
            city: loc.city,
            label: loc.displayName,
            lat: loc.lat,
            lng: loc.lng,
            st: loc.state,
            value: loc.city,
            zip: loc.zip,
          });
          this.mainSearchForm.controls['radius'].setValue(HOLISTA_CONSTANT.DEFAULT_RADIUS);
          this.providerSearchParams['radius'] = HOLISTA_CONSTANT.DEFAULT_RADIUS;
        }
      },
        (error) => console.log(error.message));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  openSearchModal() {
    this.modalRef = this.modalService.open(SearchModalComponent, {
      data: { mainSearchFormData: this.mainSearchFormData, providerSearchParams: this.providerSearchParams }
    });
    this.modalRef.onClose.subscribe((data: any) => {
      if (data) {
        const { mainSearchFormData, providerSearchParams } = data;
        this.isSearchByProvider = mainSearchFormData.searchBy === "provider";
        this.providerSearchParams = { ...this.providerSearchParams, ...providerSearchParams };
        this.mainSearchForm.patchValue(mainSearchFormData);
        this.mainSearchFormData = mainSearchFormData;
        this.submitMainFilter();
      }
    });
  }

  changeSearchBy(event) {
    if (event.target.value === 'provider') {
      this.isSearchByProvider = true;
      this.mainSearchForm.controls['bundle'].setValue(null);
      this.mainSearchForm.get('provider').setValidators([Validators.required]);
      this.mainSearchForm.get('bundle').clearValidators();
    } else {
      this.isSearchByProvider = false;
      this.mainSearchForm.controls['provider'].setValue(null);
      this.mainSearchForm.get('bundle').setValidators([Validators.required]);
      this.mainSearchForm.get('provider').clearValidators();
    }
    this.mainSearchForm.get('provider').updateValueAndValidity();
    this.mainSearchForm.get('bundle').updateValueAndValidity();
    this.clearAutocompleteSearch();
  }

}
